import useWindowSize from '@/hooks/useWindowSize';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FC } from 'react';
import { HomePageMakeModelsBar } from '../HomePageMakeModelsBar/HomePageMakeModelsBar';
import { HomePageSearchBar } from '../HomePageSeachBar/HomePageSearchBar';

const DynamicVideo = dynamic(() => import('@/components/atoms/Video/Video'), {
  loading: () => (
    <div className="relative h-full w-full">
      <Image
        className="absolute z-0 h-full w-full object-cover"
        src={
          'https://homepage-hero-videos.s3.amazonaws.com/hero-poster-desktop.png'
        }
        alt="Hero Video Poster"
        fill
      />
    </div>
  ),
  ssr: false,
});

export type HeroSectionProps = {
  makes: string[];
  models: { [key: string]: string[] };
};
export const HeroSection: FC<HeroSectionProps> = (props) => {
  const { isDesktop, isTablet, isMobile } = useWindowSize();

  let screenSize: 'mobile' | 'desktop' | 'tablet' = 'mobile';
  if (isDesktop) {
    screenSize = 'desktop';
  } else if (isTablet) {
    screenSize = 'tablet';
  }
  const src = `https://homepage-hero-videos.s3.amazonaws.com/hero-video-${screenSize}-2.mp4`;
  const posterSrc = `https://homepage-hero-videos.s3.amazonaws.com/hero-poster-${screenSize}-2.png`;

  return (
    <div className="relative w-full">
      <div className="absolute z-0 h-full w-full">
        <DynamicVideo
          src={src}
          posterSrc={posterSrc}
          label="home page background video"
          alt="home page background poster"
        />
      </div>

      <div className="relative flex w-full flex-col items-center justify-center px-l py-[42px] md:items-start md:px-[40px] md:py-[58px]">
        <h1 className="mb-s text-center text-h2SemiBold text-white md:text-h1SemiBold">
          EV for all
        </h1>
        <h2 className="mb-xl text-center text-body1Regular font-normal text-white md:mb-[52px] md:text-h2Regular">
          {SEOMetaTags.homePage.h2Tag}
        </h2>
        {process.env.NEXT_PUBLIC_SHOW_SEARCH_BAR === 'true' ? (
          <HomePageSearchBar
            makes={props.makes}
            models={props.models}
            placeholder={
              isMobile ? 'Search' : 'Search by make, model or keyword'
            }
          />
        ) : (
          <div className="flex w-full flex-col">
            <HomePageMakeModelsBar makes={props.makes} models={props.models} />
          </div>
        )}
      </div>
    </div>
  );
};

import { parseBuildTrim } from '@/components/organisms/ChooseTrimForm';
import {
  HomeTemplate,
  HomeTemplateProps,
} from '@/components/template/HomeTemplate/HomeTemplate';
import { getEstFuelSavings } from '@/lib/getEstFuelSavings';
import { getSearchMakesAndModels } from '@/lib/getSearchMakesAndModels';
import { RawTrimDetails } from '@/lib/schema/build/types';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { BasePageProps } from '@/types/page';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function Home(props: HomeTemplateProps) {
  const {
    bestForFamily = [],
    mostAffordable = [],
    longestRange = [],
    makes,
    models,
    estFuelSavingsByType,
  } = props;
  return (
    <main>
      <HomeTemplate
        bestForFamily={bestForFamily}
        longestRange={longestRange}
        mostAffordable={mostAffordable}
        makes={makes}
        models={models}
        estFuelSavingsByType={estFuelSavingsByType}
      />
    </main>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  if (process.env.NEXT_PUBLIC_NEW_LANDING_PAGE_ENABLED === 'true') {
    const promises = ['range', 'family', 'price'].map((sortBy) =>
      fetch(`${process.env.API_ENDPOINT}/api/build/selector?sortBy=${sortBy}`)
    );

    const responses = await Promise.all(promises);
    const jsons = await Promise.all(
      responses.map((response) => response.json() as Promise<RawTrimDetails[]>)
    );

    const autoCompleteResults = await getSearchMakesAndModels();

    return {
      props: {
        ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        longestRange: jsons[0].map(parseBuildTrim),
        bestForFamily: jsons[1].map(parseBuildTrim),
        mostAffordable: jsons[2].map(parseBuildTrim),
        makes: autoCompleteResults.searchMakes,
        models: autoCompleteResults.searchModels,
        estFuelSavingsByType: await getEstFuelSavings(),
        metadata: {
          title: SEOMetaTags.homePage.title,
          description: SEOMetaTags.homePage.description,
        },
      } satisfies BasePageProps<HomeTemplateProps>,
      revalidate: 60,
    };
  }
  return {
    props: {},
  };
};

import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import Input from '@/components/atoms/legacy/Input';
import Snackbar from '@/components/atoms/legacy/Snackbar';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { validateEmail } from '@/lib/validators';
import { Component } from '@/types/interfaces';
import React, { useState } from 'react';

type SnackbarType = 'SUCCESS' | 'ERROR';

interface SnackbarConfig {
  message: string;
  type: SnackbarType;
}

export const ReadyToConnectModal: React.FC<Component> = () => {
  const [userEmail, setUserEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    message: '',
    type: 'SUCCESS',
  });
  const { handleActionTracking } = useAnalytics();

  const handleSetEmail = (value: string) => {
    setUserEmail(value);
  };

  const submitEmail = async () => {
    if (userEmail === '') {
      setSnackbarConfig({
        message: 'Email is required',
        type: 'ERROR',
      });
      setShowSnackbar(true);
      return;
    }
    if (!validateEmail(userEmail)) {
      setSnackbarConfig({
        message: 'Email is invalid, please try a different email',
        type: 'ERROR',
      });
      setShowSnackbar(true);
      return;
    }
    handleActionTracking(GOOGLE_TRACK_INFO.readyToConnectEmailSubmitButton);
    setIsSubmitting(true);

    try {
      const res = await fetch(`/api/subscribe/?userEmail=${userEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        setSnackbarConfig({
          message: 'Email submitted successfully!',
          type: 'SUCCESS',
        });
        setUserEmail('');
        handleActionTracking(
          GOOGLE_TRACK_INFO.readyToConnectEmailSubmitSuccess
        );
      } else {
        setSnackbarConfig({
          message: `Error: ${res.statusText}`,
          type: 'ERROR',
        });
        handleActionTracking(GOOGLE_TRACK_INFO.readyToConnectEmailSubmitFail);
      }
    } catch {
      setSnackbarConfig({
        message: 'Error: Something went wrong',
        type: 'ERROR',
      });
      handleActionTracking(GOOGLE_TRACK_INFO.readyToConnectEmailSubmitFail);
    }

    setShowSnackbar(true);
    setIsSubmitting(false);
  };

  return (
    <>
      <section className="col-span-full rounded-b bg-brandMidnight p-[20px] text-center m:p-[40px] l:col-span-6 l:rounded-b-none l:rounded-r l:p-[80px]">
        <Icon className="m-auto h-[30px] w-[30px]" iconId={IconIds.Mail} />
        <Heading
          className="mb-[12px] text-display3 text-white"
          level={HeadingLevels.H2}
        >
          Ready to connect?
        </Heading>
        <p className="mb-[32px] text-body1 text-white">
          Get access to insightful EV resources and trending news curated by our
          team!
        </p>
        <section className="flex h-full flex-col items-center m:h-14 m:flex-row">
          <Input
            className="mb-[15px] h-14 w-full pr-[12px] text-body1 m:mb-0 m:mr-[15px]"
            value={userEmail}
            onChange={handleSetEmail}
            placeholder="Email Address"
          />
          <Button
            className="h-14 w-[200px] w-full rounded-md border-2 border-teal-400 bg-indigo-800 bg-transparent p-2 px-6 text-sm text-white md:w-[167px] lg:w-[105px]"
            variant={ButtonVariants.Default}
            disabled={isSubmitting}
            onClick={submitEmail}
          >
            {isSubmitting ? (
              <Icon
                className="m-auto h-[24px] w-[24px] animate-spin"
                iconId={IconIds.Loader}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </section>
      </section>
      <Snackbar
        isVisible={showSnackbar}
        setIsVisible={setShowSnackbar}
        type={snackbarConfig.type}
        message={snackbarConfig.message}
      />
    </>
  );
};

import Link from '@/components/atoms/Link/Link';
import { Skeleton } from '@/components/atoms/Skeleton';
import ClampedText from '@/components/molecules/ClampedText';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { BlogPreview } from '@/types/blog';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

export interface BlogListingLinkProps {
  layout?: 'row' | 'column';
  blog: BlogPreview;
  showExcerpt?: boolean;
  titleVariant?: 'large' | 'small';
}

export const BlogListingLinkSkeleton = ({
  layout,
}: Pick<BlogListingLinkProps, 'layout'>) => {
  return (
    <div
      className={`flex h-full w-full rounded-small bg-white ${layout === 'column' ? 'flex-col' : 'flex-row'}`}
    >
      <Skeleton
        className={`flex ${layout === 'row' ? 'h-full w-[302px]' : 'h-[260px]'}`}
      />
      <section className="flex flex-1 flex-col items-start justify-start gap-s p-l">
        <Skeleton className="h-[20px] w-[40px]" />
        <Skeleton className="h-[22px] w-full" />
      </section>
    </div>
  );
};

export const BlogListingLink: React.FC<BlogListingLinkProps> = ({
  blog,
  layout = 'column',
  showExcerpt = false,
  titleVariant = 'small',
}) => {
  return (
    <Link
      href={`/news/${blog.slug}`}
      analyticsEvent={{
        ...GOOGLE_TRACK_INFO.blogArticlePageButton,
        pageTarget: `/news/${blog.slug}`,
      }}
    >
      <div
        className={clsx('flex h-full w-full rounded-small bg-white', {
          'flex-col': layout === 'column',
          'flex-row': layout === 'row',
        })}
      >
        {!!blog?.image && (
          <div
            className={clsx('flex flex-1', {
              'flex-col justify-center': layout === 'row',
            })}
          >
            <Image
              src={blog.image}
              alt="Article image"
              width={1600}
              height={900}
              className={clsx('h-auto', {
                'w-full rounded-t-small': layout === 'column',
                'rounded-l-small': layout === 'row',
              })}
            />
          </div>
        )}
        <section className="flex flex-1 flex-col items-start justify-start p-l">
          <ClampedText className="mb-xs text-body2Light text-neutral-800">
            {blog?.categories?.join(', ').toUpperCase()}
          </ClampedText>
          {titleVariant === 'large' ? (
            <h4
              className={clsx('text-h4SemiBold text-neutral-900', {
                'mb-s': showExcerpt,
              })}
            >
              {blog.title}
            </h4>
          ) : (
            <p className="text-body1Medium text-neutral-900">{blog.title}</p>
          )}
          {showExcerpt && (
            <div>
              <span className="line-clamp-2 text-body1Light text-neutral-900">
                {blog.excerpt}
              </span>
            </div>
          )}
        </section>
      </div>
    </Link>
  );
};
